$(document).ready(function() {
    //
    // Scroll To Top
    //

    (function() {
        var $document = $(document);
        var $scrollTotop = $(".scroll-to-top");
        if ($scrollTotop.length > 0) {
            if ($document.scrollTop() > 300) {
                $scrollTotop.show();
            }
            $document.on("scroll", function() {
                if ($document.scrollTop() > 300) {
                    $scrollTotop.show();
                } else {
                    $scrollTotop.fadeOut();
                }
            });
            $scrollTotop.click(function(e) {
                e.preventDefault();
                $("html, body").animate({
                        scrollTop: 0,
                    },
                    500
                );
                return false;
            });
        }
    })();
});

var storage = {
    set(key, value) {
        if (key === 'access_token') {
            sessionStorage.setItem(key, value);
        } else {
            sessionStorage.setItem(key, JSON.stringify(value));
        }
    },
    get(key) {
        if (key === 'access_token') {
            return sessionStorage.getItem(key);
        } else {
            return JSON.parse(sessionStorage.getItem(key));
        }
    },
    remove(key) {
        sessionStorage.removeItem(key);
    },
    clear() {
        sessionStorage.clear();
    },
}

function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            resolve(e.target.result);
        };
        // readAsDataURL
        fileReader.readAsDataURL(blob);
        fileReader.onerror = () => {
            reject(new Error('blobToBase64 error'));
        };
    });
}